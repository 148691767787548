jQuery(function($){
  $.dynamicTable = function(options){
    options = options != null ? options : {}
    /*
      options = {
        dataTableDefaults: Hash, DateTableのデフォルトオプション
        dataTableOptions: Hash, DateTableのオプション
        dynamicTableSelecter: String, テーブルのセレクタ
        searchAreaSelecter: String, 検索フォームのセレクタ
        reseterSelecter: String, リセットボタンのセレクタ
        exporterSerecter: String, エクスポートボタンのセレクタ
        custumSearchers: Hash, カスタム検索関数入りのHash
       }
    */

    // 共通関数
    var renderError = function(msg){
      $dynamicTable.html('<span style="color: #FF0000">' + msg + '<span>');
    };

    // 定数
    const STANDBYTIME = 400;
    const CONTROLLKEYCODE = [9, 16, 17, 18, 28, 29, 37, 38, 39, 40, 144, 240, 242, 244];
    const REGMULTIPLEPARAM = new RegExp('\\[\\]$');
    const REGGETPARAMPREFIX = new RegExp('^\\?');

    // デフォルト引数
    var dynamicTableSelecter = options.dynamicTableSelecter ? options.dynamicTableSelecter : '#dynamic-table';
    var searchAreaSelecter = options.searchAreaSelecter ? options.searchAreaSelecter : '#search-area'
    var reseterSelecter = options.reseterSelecter ? options.reseterSelecter : '#search-area .search-reset'
    var exporterSerecter = options.exporterSerecter ? options.exporterSerecter : '.table-export';

    // 変数
    var timeoutTimer;
    var $dynamicTable = $(dynamicTableSelecter);
    var $searchArea = $(searchAreaSelecter);
    var $reseter = $(reseterSelecter);
    var $exporter = $(exporterSerecter);

    var indexFull = $dynamicTable.data('indexFull');
    var exportPath;
    if($dynamicTable.data('exportPath')){
      exportPath = $dynamicTable.data('exportPath');
    }else{
      exportPath = location.pathname + '/export.json';
    }

    var searchParamsInitLock = false;

    // 自前のセレクト行保持機能用変数(indexFull: true時はdataTable.selectのみにて可能)
    var selectable = !indexFull && (options.dataTableDefaults && options.dataTableDefaults.select);
    if(selectable){
      if(!options.dataTableDefaults.rowId){
        // indexFull: false時にセレクト機能を使う場合はrowId指定必須
        renderError('system-error: rowId missing.')
        return;
      }

      $dynamicTable.selecedRowIds = []; // 選択された行のID
      $dynamicTable.filterdRowIds = []; // フィルタされた行のID
      $dynamicTable.filterdSelectedRowIds = function(){
        if($dynamicTable.filterdRowIds.length > 0){
          return $dynamicTable.selecedRowIds.filter(function(i){ return $.inArray(i, $dynamicTable.filterdRowIds) > -1 });
        }
        return $dynamicTable.selecedRowIds;
      };
      var checkSelectedRowId = function(rowId){
        return $.inArray(rowId.toString(), $dynamicTable.selecedRowIds) > -1;
      };
      var selecteRowId = function(rowId){
        if($.inArray(rowId.toString(), $dynamicTable.selecedRowIds) == -1){
          $dynamicTable.selecedRowIds.push(rowId);
        }
      };
      var deselecteRowId = function(rowId){
        $dynamicTable.selecedRowIds = $dynamicTable.selecedRowIds.filter(function(val){ return val != rowId; });
      };
      // 絞り込み済み全選択
      var allSelectSerecter = options.allSelectSerecter ? options.allSelectSerecter : '#all-select';
      var $allSerecter = $(allSelectSerecter);
      var allDeselectSerecter = options.allDeselectSerecter ? options.allDeselectSerecter : '#all-deselect';
      var $allDeserecter = $(allDeselectSerecter);
      var exportRowIdsPath;
      if($dynamicTable.data('exportRowIdsPath')){
        exportRowIdsPath = $dynamicTable.data('exportRowIdsPath');
      }else{
        exportRowIdsPath = location.pathname + '/export_row_ids.json';
      }
      var ajaxGetRowIdLock = false;
      var loadFilderdRowIds = function(doneCallback){
        var params = {}
        $.each($dynamicTable.searchParams, function(name, ps){ params[name] = ps.value; });
        params['row_id'] = options.dataTableDefaults.rowId;
        return $.ajax({
          url: exportRowIdsPath,
          dataType: 'json',
          type: 'post',
          data: params,
        })
        .done(function(data, textStatus, jqXHR){
          if(!Object.prototype.toString.call(data) === '[object Array]'){
            deferred.reject();
          }else{
            doneCallback(data, textStatus, jqXHR);
          }
        })
        .fail(function(jqXHR, textStatus){
          alert('system-error: get rowIds failed.');
        })
        .always(function(){
          ajaxGetRowIdLock = false;
        });
      };
      var selectAllRowIds = function(){
        loadFilderdRowIds(function(data, textStatus, jqXHR){
          $dynamicTable.selecedRowIds = data.map(function(rowId){ return rowId.toString(); });
          var rowIds = data.map(function(rowId){ return '#' + rowId; });
          $dynamicTable.DataTable().rows(rowIds).select();
        });
      }
      var deselectAllRowIds = function(){
        $dynamicTable.selecedRowIds = [];
        $dynamicTable.DataTable().rows().deselect();
      }
    }

    // パラメータURL保持機能オンオフ
    var urlParameterable = $dynamicTable.data('urlParams') != null ? $dynamicTable.data('urlParams') : true;

    // 共通設定
    $.extend($.fn.dataTable.defaults, {
      language: {
        lengthMenu: $dynamicTable.data('languageLengthMenu'),
        zeroRecords: $dynamicTable.data('languageZeroRecords'),
        info: $dynamicTable.data('languageInfo'),
        infoEmpty: $dynamicTable.data('languageInfoEmpty'),
        infoFiltered: $dynamicTable.data('languageInfoFiltered'),
        processing: $dynamicTable.data('languageProcessing'),
        loadingRecords: $dynamicTable.data('languageLoadingRecords'),
        emptyTable: $dynamicTable.data('languageEmptyTable'),
        paginate: {
          previous: $dynamicTable.data('languagePaginatePrevious'),
          next: $dynamicTable.data('languagePaginateNext'),
        }
      },
      columnDefs: [
        {
          targets: '_all',
          createdCell: function(td, cellData, rowData, row, col){
            $(td).attr('title', $(td).text());
          }
        }
      ],
      processing: true,
      autoWidth: false,
      fixedHeader: true /* FixedHeader プラグインが必要 */
    });
    // 個別設定追加
    if(options.dataTableDefaults){ $.extend($.fn.dataTable.defaults, options.dataTableDefaults); }


    // ヘルパ: スネークケースをキャメルケースに変換
    var snakeToCamel = function(p){
        return p.replace(/_./g,
                function(s) {
                    return s.charAt(1).toUpperCase();
                }
        );
    };

    // ヘルパ: HTMLエスケープ
    var escapeHTML = function(html) {
      return $('<div>').text(html).html();
    };

    // ヘルパ: 配列判定
    var is_array = function(obj){
      return Object.prototype.toString.call(obj) === '[object Array]';
    };

    // クエリストリングのパーサー
    var parseQueryString = function(query){
      var store = { search: {}, order: [], paging: {} };
      var others = {};
      $.each(query.split(/&/), function(_, param){
        var param_pair = param.match(/^([^=]+)=(.*)$/);
        if(param_pair){
          var name = decodeURIComponent(param_pair[1].replace(/\+/g, '%20'));
          var value = decodeURIComponent(param_pair[2].replace(/\+/g, '%20'));
          var found = name.match(regDynamicTableParamsNameSpliter);
          if(found){
            var search = found[1];
            if(search == 'sort'){
              // ソート
              var column = found[2];

              var index = -1;
              $dynamicTable.columns.some(function(c, i) {
                if(c.name == column){
                  index = i;
                  return true;
                }
              });

              store.order.push([index, value]);
            }else{
              // 検索
              if(name.match(REGMULTIPLEPARAM)){
                if(store.search[name]){
                  store.search[name].push(value);
                }else{
                  store.search[name] = [value];
                }
              }else{
                store.search[name] = value;
              }
            }
          }else{
            if(name == 'page'){
              // ページネーション(ページ)
              store.paging['page'] = parseInt(value) - 1;
            }else if(name == 'per'){
              // ページネーション(ページごと件数)
              store.paging['per'] = parseInt(value);
            }else{
              // その他
              others[name] = value;
            }
          }
        }
      });
      return { store: store, others: others };
    };

    // URL保存したパラメータ取得
    var getUrlParams = function(){
      var queries = parseQueryString(location.search.replace(REGGETPARAMPREFIX, ''))
      return queries.store;
    }

    // パラメータをURL保存
    var setUrlParams = function(search, order, paging){
      var old = parseQueryString(location.search.replace(REGGETPARAMPREFIX, ''));
      var queries = old.others; // DynamicTableに無関係なパラメータを残す

      // 検索
      if(search){
        $.each(search, function(name, param){
          if(param.value){
            if(!name.match(REGMULTIPLEPARAM) || param.value.toString() != param.defaultValue.toString()){
              queries[name] = param.value;
            }
          }
        });
      }
      // ソート
      if(order){
        $.each(order, function(_, o){
          queries['sort_' + $dynamicTable.columns[o[0]].name] = o[1];
        });
      }
      // ページネート
      if(paging){
        queries['page'] = (paging.start / paging.length) + 1;
        queries['per'] = paging.length;
      }

      // クエリ化・URL置換
      var query = '?' + $.param(queries);
      if(location.search != query){
        window.history.replaceState('', '', location.pathname + query);
      }
    }

    // 検索ルーチン(columns().search()は使用しない)
    var searchers = {
      like: function(data, value){
          return ~data.indexOf(value);
      },
      match: function(data, value){
        return data == value;
      },
      in: function(data, values){
        return $.inArray(data, Array.prototype.concat.apply([], values)) > -1;
      },
      min: function(data, value){
        var iData = parseInt(data);
        var iValue = parseInt(value);
        return iData >= iValue;
      },
      max: function(data, value){
        var iData = parseInt(data);
        var iValue = parseInt(value);
        return iData <= iValue;
      },
      from: function(data, value){
        var tData = Date.parse(data);
        var tValue = Date.parse(value);
        return tData && tValue && tData >= tValue;
      },
      to: function(data, value){
        // IE11向けにISO8601拡張形式に置換
        var dataStr = data.replace(/(\d{4})[\/-](\d{1,2})[\/-](\d{1,2})[\sT]/, '$1-$2-$3T');
        var valueStr = value.replace(/^(\d{4})[\/-](\d{1,2})[\/-](\d{1,2})$/, '$1-$2-$3');
        var tData = Date.parse(dataStr);
        var tValue = Date.parse(valueStr + 'T23:59:59');
        return tData && tValue && tData < tValue;
      },
      exist: function(data, value){
        var not = value == 'false' || value == '0';
        var is_null = data == null || data == '';
        return not ? is_null : !is_null;
      },
      daterange: function(data, value){
        var match = value.match(/(\d{4}[\/-]\d{1,2}[\/-]\d{1,2})\s-\s(\d{4}[\/-]\d{1,2}[\/-]\d{1,2})/)
        if(!match){ return true }
        var dataStr = data.replace(/(\d{4})[\/-](\d{1,2})[\/-](\d{1,2})[\sT]/, '$1-$2-$3T');
        var fromStr = match[1].replace(/^(\d{4})[\/-](\d{1,2})[\/-](\d{1,2})$/, '$1-$2-$3');
        var toStr = match[2].replace(/^(\d{4})[\/-](\d{1,2})[\/-](\d{1,2})$/, '$1-$2-$3');
        var tData = Date.parse(dataStr);
        var tFrom = Date.parse(fromStr + 'T00:00:00');
        var tTo = Date.parse(toStr + 'T23:59:59');
        return tData && tFrom && tTo && tData >= tFrom && tData <= tTo;
      },
    }
    // カスタム検索ルーチンを追加
    if(options.custumSearchers){ $.extend(searchers, options.custumSearchers); }
    var searchNames = Object.keys(searchers);
    var dynamicTableParamsNames = searchNames.concat(['sort']);
    var regDynamicTableParamsNameSpliter = new RegExp('^(' + dynamicTableParamsNames.join('|') + ')_(.*)$')

    // 検索実行器
    $.fn.dataTable.ext.search.push(function(settings, data, dataIndex) {
      var result = true;
      $.each($dynamicTable.searchParams, function(_, params){
        if(is_array(params.value) || !params.value == null || !params.value == ''){ // 空値または''は無視(['']は有効)
          result = result && searchers[params.search](data[params.index], params.value);
        }
      });
      return result;
    });

    // 検索条件取得
    var getSearchParams = function(init, storeParams){
      // 値の取得(または初期化)
      $.each($dynamicTable.searchParams, function(name, params){
        // 初期化値取得
        var initValue;
        if(init){
          if(params.initData){
            initValue = params.initData; // 強制
          }else if(storeParams && storeParams.search){
            initValue = storeParams.search[name]; // URLパラメータ
          }else if(params.defaultValue != null){
            initValue = params.defaultValue; // フォームデフォルト値
          }
          searchParamsInitLock = true;
        }
        var values = [];
        $searchArea.find('[name="' + name + '"]').each(function(){
          var val = $(this).val();
          var value;
          switch(this.formType){
            case 'radio':
            case 'checkbox':
              if(initValue != null){
                if($.inArray(val, Array.prototype.concat.apply([], [initValue])) > -1) {
                  $(this).prop('checked', true).change();
                } else {
                  $(this).prop('checked', false).change();
                }
              }
              if($(this).prop('checked')){
                value = val;
              }
              break;
            case 'hidden':
              // チェックボックスにおける未選択状態用
              value = val;
              break;
            default:
              // selectタグ含む、要jQuery1.2以上
              if(initValue != null) {
                val = initValue;
                $(this).val(val);
                if($(this)[0].nodeName == 'SELECT'){
                  $(this).trigger('change.select2');
                }
              }
              value = val;
              break;
          }
          if(value == null) { return; } // 値がなければスキップ
          values.push(value);
        });
        searchParamsInitLock = false;

        // 複値の名前が付いていない場合に、複数の値があれば例外終了
        if(name.match(REGMULTIPLEPARAM)){
          params.value = values;
        } else if(values.length > 1){
          msg = 'Error: 不正な検索フォームです(' + name + 'が複数存在します)';
          alert(msg);
          throw new Error(msg);
        } else {
          params.value = values.pop();
        }
      });
    };

    // エクスポート実行器
    var sendExporter = function(action){
      var table = $dynamicTable.DataTable();

      var form = $('<form/>', { action: action, method: 'post' });
      var csrf_token = $('meta[name=csrf-token]').attr('content');
      var csrf_param = $('meta[name=csrf-param]').attr('content');
      if (csrf_param !== undefined && csrf_token !== undefined) {
        form.append($('<input/>', { type: 'hidden', name: escapeHTML(csrf_param), value: escapeHTML(csrf_token) }));
      }

      if(indexFull){
        // 全件時はid指定、カラム先頭がidであること
        var ids = table.rows({ order: 'current', page: 'all', search: 'applied' })
                  .data().map(function(record) { return record[Object.keys(record)[0]] });
        form.append($('<input/>', { type: 'hidden', name: 'ids[]', value: '' })); // 空対応
        $.each(ids, function(_, id){
          form.append($('<input/>', { type: 'hidden', name: 'ids[]', value: id }));
        });
      }else{
        var params = table.ajax.params();
        params.per = null;
        params.page = null;
        $.each(params, function(name, value){
          if(value){
            if(is_array(value)){
              $.each(value, function(_, val){
                form.append($('<input/>', { type: 'hidden', name: name, value: val }));
              });
            }else{
              form.append($('<input/>', { type: 'hidden', name: name, value: value }));
            }
          }
        });
      }

      form.appendTo(document.body).submit();
    };

    // テーブルリセット
    var resetTable = function(){
      // 検索フォームリセット
      $searchArea.get(0).reset();
      $searchArea.find('select').each(function(){
        $(this).val(this.value).trigger('change.select2');
      });

      // 保存パラメータ削除
      if(urlParameterable){
        setUrlParams();
      }

      // テーブルリセット
      var table = $dynamicTable.DataTable();
      table.page.len($dynamicTable.lengthMenu[0]);
      table.order($dynamicTable.defaultOrder());
      getSearchParams(true);
      table.draw();
    };

    // テーブル初期化生成
    var initTable = function(columns, order, data){
      // テーブルカラム定義
      $dynamicTable.columns = columns;

      // 検索値コンテナ
      $dynamicTable.searchParams = {};
      var regSeachNameSpliter = new RegExp('^(' + searchNames.join('|') + ')_(.*)$');
      $dynamicTable.searchers = {};
      $searchArea.find('input, select').each(function(){
        this.formType = $(this)[0].nodeName == 'SELECT' ? 'select' : $(this).attr('type');
        var name = $(this).attr('name');
        var found = name.match(regSeachNameSpliter);
        if(!found) { return; } // 検索に関係のないフォームはスキップ
        var search = found[1];
        var column = found[2];

        // 初期値・強制初期値取得
        var value, initData;
        var val = $(this).attr('value');
        switch(this.formType){
          case 'radio':
          case 'checkbox':
            value = $(this).prop('checked') ? val : null;
            initData = $(this).data('initData') ? val : null;
            break;
          default:
            value = val;
            initData = $(this).data('initData');
            break;
        }

        // 既値の処理
        if($dynamicTable.searchParams[name] && value != null){
          if(column.match(REGMULTIPLEPARAM)){
            if($dynamicTable.searchParams[name].defaultValue == null){
              $dynamicTable.searchParams[name].defaultValue = [];
            }
            $dynamicTable.searchParams[name].defaultValue.push(value);
            if(initData){
              if($dynamicTable.searchParams[name].initData == null){
                $dynamicTable.searchParams[name].initData = [];
              }
              $dynamicTable.searchParams[name].initData.push(initData);
            }
          }else{ // 重複フォームは例外終了
            msg = 'Error: 不正な検索フォームです(' + name + 'が複数存在します)';
            alert(msg);
            throw new Error(msg);
          }
          return;
        }

        // 格納
        var index = -1;
        columns.some(function(c, i) {
          if(c.name == column.replace(REGMULTIPLEPARAM, '')){
            index = i;
            return true;
          }
        });
        if(index == -1) { return; } // 対応するカラムがなければスキップ
        if(!$dynamicTable.searchParams[name]){
          $dynamicTable.searchParams[name] = {
            search: search,
            index: index,
          };
        }
        if(value != null){
          if(column.match(REGMULTIPLEPARAM)){
            $dynamicTable.searchParams[name].defaultValue = [value];
          }else{
            $dynamicTable.searchParams[name].defaultValue = value;
          }
        }
        if(initData != null){
          if(column.match(REGMULTIPLEPARAM)){
            $dynamicTable.searchParams[name].initData = [initData];
          }else{
            $dynamicTable.searchParams[name].initData = initData;
          }
        }
      });

      // 検索フォーム展開
      if(urlParameterable){
        $.each(getUrlParams().search, function(name, value){
          if(value){
            $searchArea.collapse('show');
            return false;
          }
        });
      }

      // ソート
      $dynamicTable.defaultOrderValue = order;
      $dynamicTable.defaultOrder = function(){
        // DateTable().order()に代入すると二次元配列の中身が破壊されるので逐次deepコピー
        return this.defaultOrderValue.map(function(order){ return order.map(function(o){return o; }); })
      };
      // ページング
      $dynamicTable.lengthMenu = [10, 25, 50, 100]; // lengthMenuの内容取得APIが見つからなかったので明示

      // DataTableオプション
      var dataTableOptions = {
        //scrollX: true,
        //fixedColumns: {
        //  rightColumns: 1
        //},
        dom: 'liprtp',
        columns: columns,
        order: $dynamicTable.defaultOrder(),
        lengthMenu: $dynamicTable.lengthMenu,
        stateSave: true,
        stateLoadCallback: function(settings, callback){
          // URLからパラメータ取得
          var params = urlParameterable ? getUrlParams() : { search: {}, order: [], paging: {} };
          // 検索フォームと検索値初期化
          getSearchParams(true, params);
          // ページネーション
          var status = { time: new Date() };
          if(params.order && params.order.length > 0){ status.order = params.order; }
          if(params.paging.page && params.paging.per){ status.start = params.paging.page * params.paging.per; }
          if(params.paging.per){ status.length = params.paging.per; }
          callback(status);
        },
        stateSaveCallback: function(settings, data){
          if(urlParameterable){
            // URLにパラメータ保存
            setUrlParams($dynamicTable.searchParams, data.order, { start: data.start, length: data.length });
          }
        },
        rowCallback: function(row, data, index) {
          // 追加のコールバック
          if(options.callback && options.callback.rowCallback){
            options.callback.rowCallback(row, data, index);
          }
          // 自前セレクタ保持による再選択
          if(selectable){
            var rowId = data[options.dataTableDefaults.rowId];
            if(checkSelectedRowId(rowId)) {
              this.DataTable().row('#' + rowId).select();
            }
          }
        }
      };
      // 個別設定追加
      if(options.dataTableOptions){ $.extend(dataTableOptions, options.dataTableOptions); }

      if(data) {
        // データ一括取得
        dataTableOptions.data = data;
      } else {
        // データ非同期
        dataTableOptions.serverSide = true;
        dataTableOptions.ajax = {
          url: exportPath,
          type: 'post',
          data: function(data){
            // 自前のパラメータでサーバ呼び出し
            var params = {}
            params.indexFull = indexFull;
            $.each($dynamicTable.searchParams, function(name, ps){ params[name] = ps.value; });
            // ソート
            data.order.forEach(function(o){
              params['sort_' + columns[o.column].name] = o.dir;
            });
            // ページネーション
            params.per = data.length;
            params.page = data.start == 0 ? 1 : (data.start / data.length) + 1;
            return params;
          }
        };
      }

      // テーブル生成・初期表示
      var table = $dynamicTable.DataTable(dataTableOptions);


      // 検索
      var searchReload = function(){
        if(selectable){
          if(ajaxGetRowIdLock){
            setTimeout(function() { searchReload(); }, STANDBYTIME);
            return;
          }
          ajaxGetRowIdLock = true;
        }
        getSearchParams();
        table.draw();
        if(selectable){
          loadFilderdRowIds(function(data, textStatus, jqXHR){
            $dynamicTable.filterdRowIds = data.map(function(rowId){ return rowId.toString(); });
            if(options.callback.selecteReloaded){ options.callback.selecteReloaded(); }
          });
        }
      };
      // 検索トリガバインド
      var typeToBinder = {
        text: 'keyup change',
        select: 'change',
        checkbox: 'change',
        radio: 'change',
      };
      $searchArea.find('input, select').each(function(){
        $(this).on(typeToBinder[this.formType], function(e){
          if(searchParamsInitLock){ return; }
          if(e.type == 'keyup') {
            if(!($.inArray(e.which, CONTROLLKEYCODE) > -1)) {
              clearTimeout(timeoutTimer);
              timeoutTimer = setTimeout(function() { searchReload(); }, STANDBYTIME);
            }
          } else {
            searchReload();
          }
        });
      });

      // エクスポートボタンバインド
      $exporter.on('click', function(e){
        e.preventDefault();
        sendExporter($(this).data('action'));
      });

      // リセットボタンバインド
      $reseter.on('click', function(e){
        e.preventDefault();
        resetTable();
      });

      // fixedHeader位置調整
      $searchArea.on('shown hidden', function(e){
        table.fixedHeader.adjust();
      });

      // 自前セレクト行保持
      if(selectable){
        table.on('select', function (e, dt, type, indexes){
          if ( type === 'row' ) {
            selecteRowId(table.row(indexes).id());
            if(options.callback.tableOnSelect){ options.callback.tableOnSelect(e, dt, type, indexes); }
          }
        });
        table.on('deselect', function (e, dt, type, indexes){
          if ( type === 'row' ) {
            deselecteRowId(table.row(indexes).id());
          if(options.callback.tableOnDeselect){ options.callback.tableOnDeselect(e, dt, type, indexes); }
          }
        });
        // 全選択ボタン
        $allSerecter.on('click', function(e){
          e.preventDefault();
          selectAllRowIds();
          if(options.callback.selectAll){ options.callback.selectAll(); }
        });
        $allDeserecter.on('click', function(e){
          e.preventDefault();
          deselectAllRowIds();
          if(options.callback.deselectAll){ options.callback.deselectAll(); }
        });
      }
    };

    // 起動
    // テーブルのth(および一括時データ)取得後、テーブル生成
    $.ajax({
      url: exportPath,
      dataType: 'json',
      type: 'post',
      async: false,
      data: {
        index_full: indexFull
      },
      success: function(json){
        var orders = [];
        if(json.columns == null) {
          throw new Error('テーブルの初期設定の取得に失敗しました(json.columns is not found.)')
        };
        if(json.data == null) {
          throw new Error('テーブルの初期設定の取得に失敗しました(json.data is not found.)')
        };
        $.each(json.columns, function(i, c){
          c.data = snakeToCamel(c.name);
          if(c.orderable && (c.orderable == 'asc' || c.orderable == 'desc')) {
            orders.push([i, c.orderable]);
          }
        });
        // 成功したらテーブル生成
        if(indexFull){
          initTable(json.columns, orders, json.data);
        } else {
          initTable(json.columns, orders);
        }
      },
      error: function(){
        alert('テーブルの初期設定の取得に失敗しました');
      }
    });

    return $dynamicTable;
  };
});
