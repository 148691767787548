jQuery( function($) {
  var select_chain = function(event){
    var selectedVal = "\"" + ($(this).val() ? $(this).val() : "_blank") + "\"";
    var chainfor = $(this).data('chainFor');
    var target = $(""+chainfor+", "+chainfor+" input, "+chainfor+" select, "+chainfor+" textarea");

    if(target.is(':disabled') && event.type != "change"){
      $(chainfor).hide();
      $("[data-parent-is=" + selectedVal + "]").show();
    }else{
      $(chainfor).hide();
      target.prop('disabled', true);
      $("[data-parent-is=" + selectedVal + "]").show();
      $("[data-parent-is=" + selectedVal + "], [data-parent-is=" + selectedVal + "] input, [data-parent-is=" + selectedVal + "] select, [data-parent-is=" + selectedVal + "] textarea").prop('disabled', false);
    }
  };
  $('.exec-select-chain').each(select_chain).change(select_chain);
});
